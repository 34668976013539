<template>
        <b-alert
        v-if="shown"
        v-height-fade
        show
        dismissible
        fade
        class="mb-2"
        variant="primary"
        >
        <div class="alert-body">
            <strong>Apa anda ingin memasang HRS - Personal?</strong>
            <b-link class="alert-link" @click="installPWA">
                Ya, Pasang!
            </b-link>
        </div>
        </b-alert>
</template>

<script>
import { BAlert, BLink, BButton, BModal, VBModal } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BAlert,
    BLink,
    BButton,
    BModal,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data: () => ({
    shown: false,
  }),

  beforeMount() {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      this.installEvent = e;
      this.shown = true;
    });
  },

  methods: {
    dismissPrompt() {
      this.shown = false;
    },

    installPWA() {
      this.installEvent.prompt();
      this.installEvent.userChoice.then((choice) => {
        this.dismissPrompt(); // Hide the prompt once the user's clicked
        if (choice.outcome === "accepted") {
          // Do something additional if the user chose to install
        } else {
          // Do something additional if the user declined
        }
      });
    },
  },
};
</script>
